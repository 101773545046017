let config = {
  // Set the Demo Version
  demo: false,

  // you get this type data from google adsense

  // <ins class="adsbygoogle"
  //    style="display:block"
  //    data-ad-client="xxxxxxxxxxxx"
  //    data-ad-slot="xxxxxxxxxxxxxx"
  //    data-ad-format="auto"
  //    data-full-width-responsive="true"></ins>

  // googleAddsense
  data_ad_client: "ca-pub-9667891148162497", //ca-pub-your-ad-client-id
  data_ad_slot:"9154149295", //your-ad-slot-id

  //SEO Configurations
  metaDescription: "İngilizce kelime test çöz",
  metaKeyWords: "test çöz, ingilizce kelime,bilsem,",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "in", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds


  // audio question timer
  audiotimer: 30,
  mathtimer:30,

  //Firebase Configurations

  apiKey: "AIzaSyDpszpWIVZOcUM9hWbEtp0DCVS7WUO0sMQ",
  authDomain: "ingilizce-bilsem.firebaseapp.com",
  projectId: "ingilizce-bilsem",
  storageBucket: "ingilizce-bilsem.appspot.com",
  messagingSenderId: "817025164920",
  appId: "1:817025164920:web:d375495656d36ca0b72f8d",
  measurementId: "G-VW7J8LQY3F",

  //footer area
  companytext: "Bilsem öğrencileri İngilizce Test Çöz Uygulaması.",
  addresstext: "Address: Türkiye.",
  phonenumber: "+90 5.. ... .. ..",
  email: "englishbilsem@gmail.com",
  facebooklink: "https://www.facebook.com/ingilizceBilsem",
  instagramlink: "https://www.instagram.com/ingilizceBilsem",
  linkedinlink: "https://www.linkedin.com/company/ingilizceBilsem",
  weblink: "https://ingilizcebilsem.com/",
  companyname: "İngilizceBilsemTeam.",
};

export default config;
